var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"compliance-form",attrs:{"id":"compliance-form"}},[_c('h2',{staticClass:"title"},[_vm._v(_vm._s(_vm.$t("complianceForm.title")))]),_c('validation-observer',{ref:"formObserver"},[_c('form',{ref:"fullVacancyForm",staticClass:"form"},[_c('div',{staticClass:"form-compliance__group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.name),expression:"name"}],staticClass:"form-compliance__input",attrs:{"type":"text","placeholder":"","name":"name"},domProps:{"value":(_vm.name)},on:{"input":function($event){if($event.target.composing){ return; }_vm.name=$event.target.value}}}),_c('label',{attrs:{"for":"name"}},[_vm._v(_vm._s(_vm.$t("complianceForm.fio")))])]),_c('div',{staticClass:"form-compliance__group"},[_c('validation-provider',{attrs:{"rules":"email"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.email),expression:"email"}],staticClass:"form-compliance__input",attrs:{"type":"text","placeholder":"","name":"email"},domProps:{"value":(_vm.email)},on:{"input":function($event){if($event.target.composing){ return; }_vm.email=$event.target.value}}}),_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("complianceForm.email"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "email" ? "Неправильно введен email" : errors[0]))])])]}}])})],1),_c('div',{staticClass:"form-compliance__group"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.phone),expression:"phone"}],staticClass:"form-compliance__input",attrs:{"type":"text","placeholder":"","name":"phone"},domProps:{"value":(_vm.phone)},on:{"input":function($event){if($event.target.composing){ return; }_vm.phone=$event.target.value}}}),_c('label',{attrs:{"for":"email"}},[_vm._v(_vm._s(_vm.$t("complianceForm.phone")))])]),_c('div',{staticClass:"form-compliance__group"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.city),expression:"city"}],staticClass:"form-compliance__input",attrs:{"type":"text","placeholder":"","name":"city"},domProps:{"value":(_vm.city)},on:{"input":function($event){if($event.target.composing){ return; }_vm.city=$event.target.value}}}),_c('label',{attrs:{"for":"mall"}},[_vm._v(_vm._s(_vm.$t("complianceForm.city"))),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("formFill") : ""))])])]}}])})],1),_c('div',{staticClass:"form-compliance__group"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.storeAddress),expression:"storeAddress"}],staticClass:"form-compliance__input",attrs:{"type":"text","placeholder":"","name":"address"},domProps:{"value":(_vm.storeAddress)},on:{"input":function($event){if($event.target.composing){ return; }_vm.storeAddress=$event.target.value}}}),_c('label',{attrs:{"for":"mall"}},[_vm._v(_vm._s(_vm.$t("complianceForm.address"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("formFill") : ""))])])]}}])})],1),_c('div',{staticClass:"form-compliance__group"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.details),expression:"details"}],staticClass:"form-compliance__input",attrs:{"type":"text","placeholder":"","name":"purpose"},domProps:{"value":(_vm.details)},on:{"input":function($event){if($event.target.composing){ return; }_vm.details=$event.target.value}}}),_c('label',{attrs:{"for":"mall"}},[_vm._v(_vm._s(_vm.$t("complianceForm.target"))+" "),_c('span',{staticStyle:{"color":"red"}},[_vm._v(_vm._s(errors[0] === "required" ? _vm.$t("formFill") : ""))])])]}}])})],1),(_vm.isShowTerms)?_c('div',{staticClass:"terms",class:{ 'is-not-check': _vm.isNotCheckTerms }},[_c('div',{staticClass:"terms__checkout"},[_c('input',{ref:"termCheck",attrs:{"type":"checkbox","id":"term-checkbox"},on:{"change":_vm.checkTerms}}),_c('label',{staticClass:"terms__info",attrs:{"for":"term-checkbox"}},[_vm._v(_vm._s(_vm.$t("terms1")))])]),_c('div',{staticClass:"terms__descr"},[_vm._v(" "+_vm._s(_vm.$t("terms2"))+" ")])]):_vm._e(),_c('button',{staticClass:"btn-form",on:{"click":function($event){$event.preventDefault();return _vm.sendForm.apply(null, arguments)}}},[_vm._v(" "+_vm._s(_vm.$t("complianceForm.submit"))+" ")])])]),_c('div',{staticClass:"compliance-form__contacts"},[_c('h4',[_vm._v(_vm._s(_vm.$t("complianceContact")))]),_c('a',{staticClass:"color-pink",attrs:{"href":"mailto:SpeakUp@magnum.kz"}},[_vm._v(_vm._s(_vm.$t("complianceEmail")))]),_c('a',{attrs:{"href":"tel:+77770786582"}},[_vm._v(_vm._s(_vm.$t("compliancePhone")))])]),(_vm.isShowModalReceived)?_c('app-modal-received',{attrs:{"title":"thankRequest","text":"definitelyTake","image":"appeal.png","btn":"good"},on:{"close":_vm.closeModalReceived}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }