import axiosOriginal from "@/plugins/axiosUninterseptor";

export const sendComplianceForm = async (data) => {
  const response = await axiosOriginal.post(`/compliance-requests`, data);
  return response;
};
export const sendRiskForm = async (data) => {
  const response = await axiosOriginal.post(`/risk-forms`, data);
  return response;
};
