<template>
  <div class="compliance-form" id="compliance-form">
    <h2 class="title">{{ $t("complianceForm.title") }}</h2>
    <validation-observer ref="formObserver">
      <form ref="fullVacancyForm" class="form">
        <div class="form-compliance__group">
          <input
            v-model="name"
            type="text"
            placeholder=""
            class="form-compliance__input"
            name="name"
          />
          <label for="name">{{ $t("complianceForm.fio") }}</label>
        </div>

        <div class="form-compliance__group">
          <validation-provider v-slot="{ errors }" rules="email">
            <input
              v-model="email"
              type="text"
              placeholder=""
              class="form-compliance__input"
              name="email"
            />
            <label for="email"
              >{{ $t("complianceForm.email") }}
              <span style="color: red">{{
                errors[0] === "email" ? "Неправильно введен email" : errors[0]
              }}</span></label
            >
          </validation-provider>
        </div>

        <div class="form-compliance__group">
          <input
            v-model="phone"
            type="text"
            placeholder=""
            class="form-compliance__input"
            name="phone"
          />
          <label for="email">{{ $t("complianceForm.phone") }}</label>
        </div>

        <div class="form-compliance__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="city"
              type="text"
              placeholder=""
              class="form-compliance__input"
              name="city"
            />
            <label for="mall"
              >{{ $t("complianceForm.city")
              }}<span style="color: red">{{
                errors[0] === "required" ? $t("formFill") : ""
              }}</span></label
            >
          </validation-provider>
        </div>

        <div class="form-compliance__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="storeAddress"
              type="text"
              placeholder=""
              class="form-compliance__input"
              name="address"
            />
            <label for="mall"
              >{{ $t("complianceForm.address") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("formFill") : ""
              }}</span></label
            >
          </validation-provider>
        </div>

        <div class="form-compliance__group">
          <validation-provider v-slot="{ errors }" rules="required">
            <input
              v-model="details"
              type="text"
              placeholder=""
              class="form-compliance__input"
              name="purpose"
            />
            <label for="mall"
              >{{ $t("complianceForm.target") }}
              <span style="color: red">{{
                errors[0] === "required" ? $t("formFill") : ""
              }}</span></label
            >
          </validation-provider>
        </div>

        <div
          v-if="isShowTerms"
          class="terms"
          :class="{ 'is-not-check': isNotCheckTerms }"
        >
          <div class="terms__checkout">
            <input
              @change="checkTerms"
              ref="termCheck"
              type="checkbox"
              id="term-checkbox"
            />
            <label for="term-checkbox" class="terms__info">{{
              $t("terms1")
            }}</label>
          </div>
          <div class="terms__descr">
            {{ $t("terms2") }}
          </div>
        </div>

        <button class="btn-form" @click.prevent="sendForm">
          {{ $t("complianceForm.submit") }}
        </button>
      </form>
    </validation-observer>

    <div class="compliance-form__contacts">
      <h4>{{ $t("complianceContact") }}</h4>
      <a href="mailto:SpeakUp@magnum.kz" class="color-pink">{{
        $t("complianceEmail")
      }}</a>
      <a href="tel:+77770786582">{{ $t("compliancePhone") }}</a>
    </div>
    <app-modal-received
      v-if="isShowModalReceived"
      @close="closeModalReceived"
      title="thankRequest"
      text="definitelyTake"
      image="appeal.png"
      btn="good"
    />
  </div>
</template>

<script>
import { sendRiskForm } from "@/api/compliance-form";
import { ValidationProvider, ValidationObserver } from "vee-validate";
export default {
  name: "ComplianceForm",
  components: {
    ValidationProvider,
    ValidationObserver,
    AppModalReceived: () => import("@/components/modal/AppModalReceived.vue"),
    // AppSelect: () => import("@/components/common/AppSelect"),
  },
  data() {
    return {
      // form variables
      name: "",
      phone: "",
      email: "",
      city: "",
      storeAddress: "",
      details: "",
      isShowModalReceived: false,
      isShowTerms: false,
      isNotCheckTerms: false,
    };
  },
  watch: {
    name: {
      handler() {
        this.showTerms();
      },
    },
    email: {
      handler() {
        this.showTerms();
      },
    },
    phone: {
      handler() {
        this.showTerms();
      },
    },
  },
  mounted() {
    this.$refs.termCheck.checked = true;
  },
  methods: {
    sendForm() {
      this.$refs.formObserver.validate().then((success) => {
        if (this.$refs.termCheck?.checked === false) {
          this.isNotCheckTerms = true;
          return;
        }
        if (success) {
          this.questionnaireForm = {
            data: {
              name: this.name,
              phone: this.phone,
              email: this.email,
              city: this.city,
              store_address: this.storeAddress,
              details: this.details,
              createdAt: "2022-06-07T14:04:34.962Z",
              updatedAt: "2022-06-07T14:04:34.962Z",
              publishedAt: "2022-06-07T14:04:34.962Z",
              createdBy: "string or id",
              updatedBy: "string or id",
            },
          };
          sendRiskForm(this.questionnaireForm).then((res) => {
            if (res.status === 200) {
              this.isShowModalReceived = true;
            }
          });
        } else {
          document
            .querySelector(".compliance-form")
            .scrollIntoView({ behavior: "smooth" });
        }
      });
    },
    closeModalReceived() {
      this.isShowModalReceived = false;
      location.reload();
    },
    showTerms() {
      if (this.name !== "" || this.email !== "" || this.phone !== "") {
        this.isShowTerms = true;
        this.$refs.termCheck.checked = false;
      } else {
        this.isShowTerms = false;
        this.$refs.termCheck.checked = true;
      }
    },
    checkTerms() {
      if (this.$refs.termCheck.checked) {
        this.isNotCheckTerms = false;
      } else {
        this.isNotCheckTerms = true;
      }
    },
  },
};
</script>
